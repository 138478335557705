/*
 * General: Miscellaneous
 * ----------------------
 */
// 10px padding and margins
.pad {
  padding: 10px;
}

.margin {
  margin: 10px;
}

.margin-bottom {
  margin-bottom: 20px;
}

.margin-bottom-none {
  margin-bottom: 0;
}

.margin-r-5 {
  margin-right: 5px;
}

// Display inline
.inline {
  display: inline;
}

// Description Blocks
.description-block {
  display: block;
  margin: 10px 0;
  text-align: center;
  &.margin-bottom {
    margin-bottom: 25px;
  }
  > .description-header {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
  }
  > .description-text {
    text-transform: uppercase;
  }
}

// Background colors
.bg-red,
.bg-yellow,
.bg-aqua,
.bg-blue,
.bg-light-blue,
.bg-green,
.bg-navy,
.bg-teal,
.bg-olive,
.bg-lime,
.bg-orange,
.bg-fuchsia,
.bg-purple,
.bg-maroon,
.bg-black,
.bg-red-active,
.bg-yellow-active,
.bg-aqua-active,
.bg-blue-active,
.bg-light-blue-active,
.bg-green-active,
.bg-navy-active,
.bg-teal-active,
.bg-olive-active,
.bg-lime-active,
.bg-orange-active,
.bg-fuchsia-active,
.bg-purple-active,
.bg-maroon-active,
.bg-black-active {
  color: #fff !important;
}

.bg-gray {
  color: #000;
  background-color: @gray !important;
}

.bg-gray-light {
  background-color: #f7f7f7;
}

.bg-black {
  background-color: @black !important;
}

.bg-red {
  background-color: @red !important;
}

.bg-yellow {
  background-color: @yellow !important;
}

.bg-aqua {
  background-color: @aqua !important;
}

.bg-blue {
  background-color: @blue !important;
}

.bg-light-blue {
  background-color: @light-blue !important;
}

.bg-green {
  background-color: @green !important;
}

.bg-navy {
  background-color: @navy !important;
}

.bg-teal {
  background-color: @teal !important;
}

.bg-olive {
  background-color: @olive !important;
}

.bg-lime {
  background-color: @lime !important;
}

.bg-orange {
  background-color: @orange !important;
}

.bg-fuchsia {
  background-color: @fuchsia !important;
}

.bg-purple {
  background-color: @purple !important;
}

.bg-maroon {
  background-color: @maroon !important;
}

//Set of Active Background Colors
.bg-gray-active {
  color: #000;
  background-color: darken(@gray, 10%) !important;
}

.bg-black-active {
  background-color: darken(@black, 10%) !important;
}

.bg-red-active {
  background-color: darken(@red , 6%) !important;
}

.bg-yellow-active {
  background-color: darken(@yellow , 6%) !important;
}

.bg-aqua-active {
  background-color: darken(@aqua , 6%) !important;
}

.bg-blue-active {
  background-color: darken(@blue , 10%) !important;
}

.bg-light-blue-active {
  background-color: darken(@light-blue , 6%) !important;
}

.bg-green-active {
  background-color: darken(@green , 5%) !important;
}

.bg-navy-active {
  background-color: darken(@navy , 2%) !important;
}

.bg-teal-active {
  background-color: darken(@teal , 5%) !important;
}

.bg-olive-active {
  background-color: darken(@olive , 5%) !important;
}

.bg-lime-active {
  background-color: darken(@lime , 5%) !important;
}

.bg-orange-active {
  background-color: darken(@orange , 5%) !important;
}

.bg-fuchsia-active {
  background-color: darken(@fuchsia , 5%) !important;
}

.bg-purple-active {
  background-color: darken(@purple , 5%) !important;
}

.bg-maroon-active {
  background-color: darken(@maroon , 3%) !important;
}

//Disabled!
[class^="bg-"].disabled {
  .opacity(.65);
}

// Text colors
.text-red {
  color: @red !important;
}

.text-yellow {
  color: @yellow !important;
}

.text-aqua {
  color: @aqua !important;
}

.text-blue {
  color: @blue !important;
}

.text-black {
  color: @black !important;
}

.text-light-blue {
  color: @light-blue !important;
}

.text-green {
  color: @green !important;
}

.text-gray {
  color: @gray !important;
}

.text-navy {
  color: @navy !important;
}

.text-teal {
  color: @teal !important;
}

.text-olive {
  color: @olive !important;
}

.text-lime {
  color: @lime !important;
}

.text-orange {
  color: @orange !important;
}

.text-fuchsia {
  color: @fuchsia !important;
}

.text-purple {
  color: @purple !important;
}

.text-maroon {
  color: @maroon !important;
}

.link-muted {
  color: darken(@gray, 30%);
  &:hover,
  &:focus {
    color: darken(@gray, 40%);
  }
}

.link-black {
  color: #666;
  &:hover,
  &:focus {
    color: #999;
  }
}

// Hide elements by display none only
.hide {
  display: none !important;
}

// Remove borders
.no-border {
  border: 0 !important;
}

// Remove padding
.no-padding {
  padding: 0 !important;
}

// Remove margins
.no-margin {
  margin: 0 !important;
}

// Remove box shadow
.no-shadow {
  box-shadow: none !important;
}

// Unstyled List
.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-group-unbordered {
  > .list-group-item {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

// Remove border radius
.flat {
  .border-radius(0) !important;
}

.text-bold {
  &, &.table td, &.table th {
    font-weight: 700;
  }
}

.text-sm {
  font-size: 12px;
}

// _fix for sparkline tooltip
.jqstooltip {
  padding: 5px !important;
  width: auto !important;
  height: auto !important;
}

// Gradient Background colors
.bg-teal-gradient {
  .gradient(@teal; @teal; lighten(@teal, 16%)) !important;
  color: #fff;
}

.bg-light-blue-gradient {
  .gradient(@light-blue; @light-blue; lighten(@light-blue, 12%)) !important;
  color: #fff;
}

.bg-blue-gradient {
  .gradient(@blue; @blue; lighten(@blue, 7%)) !important;
  color: #fff;
}

.bg-aqua-gradient {
  .gradient(@aqua; @aqua; lighten(@aqua, 7%)) !important;
  color: #fff;
}

.bg-yellow-gradient {
  .gradient(@yellow; @yellow; lighten(@yellow, 16%)) !important;
  color: #fff;
}

.bg-purple-gradient {
  .gradient(@purple; @purple; lighten(@purple, 16%)) !important;
  color: #fff;
}

.bg-green-gradient {
  .gradient(@green; @green; lighten(@green, 7%)) !important;
  color: #fff;
}

.bg-red-gradient {
  .gradient(@red; @red; lighten(@red, 10%)) !important;
  color: #fff;
}

.bg-black-gradient {
  .gradient(@black; @black; lighten(@black, 10%)) !important;
  color: #fff;
}

.bg-maroon-gradient {
  .gradient(@maroon; @maroon; lighten(@maroon, 10%)) !important;
  color: #fff;
}

//Description Block Extension
.description-block {
  .description-icon {
    font-size: 16px;
  }
}

//Remove top padding
.no-pad-top {
  padding-top: 0;
}

//Make position static
.position-static {
  position: static !important;
}

//List utility classes
.list-header {
  font-size: 15px;
  padding: 10px 4px;
  font-weight: bold;
  color: #666;
}

.list-seperator {
  height: 1px;
  background: @box-border-color;
  margin: 15px 0 9px 0;
}

.list-link {
  > a {
    padding: 4px;
    color: #777;
    &:hover {
      color: #222;
    }
  }
}

//Light font weight
.font-light {
  font-weight: 300;
}

//User block
.user-block {
  .clearfix();
  img {
    width: 40px;
    height: 40px;
    float: left;
  }
  .username,
  .description,
  .comment {
    display: block;
    margin-left: 50px;
  }
  .username {
    font-size: 16px;
    font-weight: 600;
  }
  .description {
    color: #999;
    font-size: 13px;
  }
  &.user-block-sm {
    img {
      &:extend(.img-sm);
    }
    .username,
    .description,
    .comment {
      margin-left: 40px;
    }
    .username {
      font-size: 14px;
    }
  }
}

//Image sizes
.img-sm,
.img-md,
.img-lg {
  float: left;
}

.img-sm {
  width: 30px !important;
  height: 30px !important;
  + .img-push {
    margin-left: 40px;
  }
}

.img-md {
  width: 60px;
  height: 60px;
  + .img-push {
    margin-left: 70px;
  }
}

.img-lg {
  width: 100px;
  height: 100px;
  + .img-push {
    margin-left: 110px;
  }
}

// Image bordered
.img-bordered {
  border: 3px solid @gray;
  padding: 3px;
}

.img-bordered-sm {
  border: 2px solid @gray;
  padding: 2px;
}

//General attachemnt block
.attachment-block {
  border: 1px solid @box-border-color;
  padding: 5px;
  margin-bottom: 10px;
  background: #f7f7f7;

  .attachment-img {
    max-width: 100px;
    max-height: 100px;
    height: auto;
    float: left;
  }
  .attachment-pushed {
    margin-left: 110px;
  }
  .attachment-heading {
    margin: 0;
  }
  .attachment-text {
    color: #555;
  }
}

.connectedSortable {
  min-height: 100px;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sort-highlight {
  background: #f4f4f4;
  border: 1px dashed #ddd;
  margin-bottom: 10px;
}

.full-opacity-hover {
  .opacity(.65);
  &:hover {
    .opacity(1);
  }
}

// Charts
.chart {
  position: relative;
  overflow: hidden;
  width: 100%;
  svg,
  canvas {
    width: 100% !important;
  }
}
