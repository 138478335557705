// @import "bootstrap/bootstrap";
@import "admin-lte/AdminLTE";



// Wir überschreiben die Screen Size, damit es kein Responsive Verhalten gibt.
@screen-xs:                  10px;
@screen-sm:                  10px;
@screen-md:                  10px;

@modal-lg:                    900px;
@modal-md:                    900px;
@modal-sm:                    900px;




.layout-boxed .wrapper {
  margin-left:10px;
}

.main-header .navbar .logo {width:260px;text-align: left;}
.main-header .navbar {min-width:1000px;margin-left:0;}
.container {}
.content-header h1 {color:@orange }
.container {margin-left:0;}

// Form Required Felder mit Markierung
:required {
  border-left: 3px solid #00c0ef!important;
}

.has-error > :required {
  border-left: 3px solid #dd4b39!important;
}

textarea.two-rows { height: 50px;}
textarea.height-normal { height: 100px;}
textarea.height-middle { height: 200px;}
textarea.height-big { height: 400px;}
textarea.height-description { height: 250px;}
textarea.height-description-days { height: 150px;}
textarea.height-description-mentoring-coverage { height: 50px;}
textarea { resize:none; }

.no-underline {text-decoration: none!important;}

.inline_edit span{

  text-decoration:underline;
  cursor: pointer;

}

h1.mainTitle {
  font-size: 16px;
  margin-top:0;
  font-weight: bold;
  color:@orange;}

.form-info {
  color:@gray-dark;
}



// .icheckbox_square-orange
.input-group-addon {padding:5px 12px;}
.input-group .input-group-addon {}

.with-small-margin-bottom{margin-bottom:3px;}

.max-w-date {max-width:95px;min-width:95px;}
.small-integer-input {max-width:45px;min-width:45px;}
.icheck-in-table {margin-left:8px;}
.js-weekday {width:12px;}

table.vertical-align > tbody > tr > td {
  vertical-align: middle;
}


.bg-part-overnight {
  background-color:#ffc;
}
.box  .box-part-overnight {
  border-top: 3px solid #ffc!important;
}


table > tbody > tr > td.sum {
  border-top:2px solid #666!important;
  font-weight: bold;
}


table > tbody > tr > td.final {
  font-weight: bold;
  font-size:16px;
}

table > tbody > tr > td.priceHighlight {
  color:#001199!important;
  font-weight:bold;
}
// background-color: rgb(239, 206, 206);
.appointmentExpired {

  color: #b11714;

}
@media (min-width: 768px) {



}

#previewModal .modal-dialog {
  min-width:760px;
  width:1160px!important;
  margin-left: 30px ;
  margin-top: 30px ;
}


#partnerModal .modal-dialog {
  min-width:760px;
  height:90%!important;
  width:1160px!important;
  margin-left: 30px ;
  margin-top: 30px ;
}
#partnerModal .modal-dialog .modal-content {
  height:100%;
}

#partnerModal iframe {
  width:99%;
  height:99%;
}

#sihotTransferModal .modal-dialog {
  min-width:760px;
  height:90%!important;
  width:1160px!important;
  margin-left: 30px ;
  margin-top: 30px ;
}
#sihotTransferModal .modal-content {
  padding: 20px;
}


td.doSort {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* Loader */
.spinner {
  display:none;position:absolute;left:235px;top:-95px;
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #666;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}