/*
 * Component: Sidebar
 * ------------------
 */
//Main Sidebar
// ``` .left-side has been deprecated as of 2.0.0 in favor of .main-sidebar ```

.main-sidebar,
.left-side {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  min-height: 100%;
  width: @sidebar-width;
  z-index: 810;
  //Using disposable variable to join statements with a comma
  @transition-rule: @transition-speed @transition-fn,
  width @transition-speed @transition-fn;
  .transition-transform(@transition-rule);
  @media (max-width: @screen-header-collapse) {
    padding-top: 100px;
  }
  @media (max-width: @screen-xs-max) {
    .translate(-@sidebar-width, 0);
  }
  .sidebar-collapse & {
    @media (min-width: @screen-sm) {
      .translate(-@sidebar-width, 0);
    }
  }
  .sidebar-open & {
    @media (max-width: @screen-xs-max) {
      .translate(0, 0);
    }
  }
}

.sidebar {
  padding-bottom: 10px;
}

// remove border from form
.sidebar-form {
  input:focus {
    border-color: transparent;
  }
}

//Sidebar user panel
.user-panel {
  position: relative;
  width: 100%;
  padding: 10px;
  overflow: hidden;
  .clearfix();
  > .image > img {
    width: 100%;
    max-width: 45px;
    height: auto;
  }
  > .info {
    padding: 5px 5px 5px 15px;
    line-height: 1;
    position: absolute;
    left: 55px;
    > p {
      font-weight: 600;
      margin-bottom: 9px;
    }
    > a {
      text-decoration: none;
      padding-right: 5px;
      margin-top: 3px;
      font-size: 11px;
      > .fa,
      > .ion,
      > .glyphicon {
        margin-right: 3px;
      }
    }
  }
}

// Sidebar menu
.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  //First Level
  > li {
    position: relative;
    margin: 0;
    padding: 0;
    > a {
      padding: 12px 5px 12px 15px;
      display: block;
      > .fa,
      > .glyphicon,
      > .ion {
        width: 20px;
      }
    }
    .label,
    .badge {
      margin-top: 3px;
      margin-right: 5px;
    }
  }
  li.header {
    padding: 10px 25px 10px 15px;
    font-size: 12px;
  }
  li > a > .fa-angle-left {
    width: auto;
    height: auto;
    padding: 0;
    margin-right: 10px;
    margin-top: 3px;
  }
  li.active {
    > a > .fa-angle-left {
      .rotate(-90deg);
    }
    > .treeview-menu {
      display: block;
    }
  }

  // Tree view menu
  .treeview-menu {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 5px;
    .treeview-menu {
      padding-left: 20px;
    }
    > li {
      margin: 0;
      > a {
        padding: 5px 5px 5px 15px;
        display: block;
        font-size: 14px;
        > .fa,
        > .glyphicon,
        > .ion {
          width: 20px;
        }
        > .fa-angle-left,
        > .fa-angle-down {
          width: auto;
        }
      }
    }
  }
}
